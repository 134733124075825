<template>
  <v-app>
    <dashboard-core-app-bar />

    <dashboard-core-drawer />

    <dashboard-core-view />
  </v-app>
</template>

<script>
export default {
  name: "DashboardIndex",

  components: {
    DashboardCoreAppBar: () => import("@/components/layout/AppBar"),
    DashboardCoreDrawer: () => import("@/components/layout/Drawer"),
    DashboardCoreView: () => import("@/components/layout/View"),
  },

  data: () => ({
    expandOnHover: false,
  }),
};
</script>
